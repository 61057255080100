"use client";
import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import Logo from "@/public/Logo.png";
import Image from "next/image";
import AuthModal from "../auth/AuthModal";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { BookOpen, Briefcase, Edit3, FileText, Flag, Gift, HelpCircle, Home, Info, Menu, Newspaper, Percent, Phone, Scale, Shield, Star, User } from "lucide-react";
import Link from "next/link";

import UserAvatar from "./UserAvatar";


const Navbar = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState<"login" | "register">("login");
    const [hasSession, setHasSession] = useState(false);

    useEffect(() => {
        checkSessionStatus();
    }, [])

    const checkSessionStatus = async () => {
        try {
            const response = await fetch("/api/session/check", { method: "POST" });
            const result = await response.json();
            setHasSession(result.success);
        } catch (error) {
            console.error("Error checking session:", error);
        }
    }
    

    return (
        <nav className="top-0 left-0 z-20 fixed flex justify-between items-center px-20 max-[769px]:px-5 w-screen h-16 navbar-container-main backdrop-blur-lg bg-white bg-opacity-5 ">


            <Image loading="lazy" src={Logo} alt="Serviced Apartments Logo" width={150} />

            <div className="flex gap-2 max-[769px]:hidden">
                <div className="flex gap-2">
                    <Button className=" bg-transparent rounded-sm w-36 font-poppins text-primary  max-[1441px]:w-32 shadow-none hover:bg-primary hover:bg-opacity-10">
                        <Link href={process.env.HOST_URL || 'https://apartment-host.webmotech.com'} target="_blank" rel="noopener noreferrer" passHref className="text-sm max-[1441px]:text-xs">
                            List your property
                        </Link>
                    </Button>
                </div>

                {
                    !hasSession &&
                    <div className="flex gap-2">
                        <Button className="bg-white rounded-2xl w-20 font-poppins text-white bg-opacity-10 border backdrop-blur-xl max-[1441px]:w-20  hover:bg-white hover:bg-opacity-10 hover:scale-[1.02] duration-100 border-opacity-0 transition-transform" onClick={(e) => {
                            setModalOpen(true);
                            setActiveTab("login");
                        }}>
                            Login
                        </Button>
                        {/* <Button size="lg" className="bg-white rounded-sm w-24 font-poppins text-primary hover:text-white max-[1441px]:w-20" onClick={() => {
                            setModalOpen(true);
                            setActiveTab("register");
                        }}>
                            Register
                        </Button> */}
                    </div>
                }
                {
                    hasSession && (
                       <UserAvatar />
                    )
                }
            </div>


            <div className="max-[769px]:flex justify-center items-center hidden">
                {!hasSession ? (
                    <Button className="flex flex-col justify-center items-center border-2 border-primary bg-transparent mr-3 rounded-full w-9 h-9 text-primary hover:text-white" onClick={() => setModalOpen(true)}>
                        <User className="" />
                    </Button>
                ) : (
                    <UserAvatar />
                )}
                <Sheet>
                    <SheetTrigger asChild className="p-0">
                        <Button variant="ghost">
                            <Menu style={{ width: '30px', height: '30px', color: '#fff' }} />
                        </Button>
                    </SheetTrigger>
                    <SheetContent
                        side="right"
                        className="w-[50vw] max-[535px]:w-[90vw] font-poppins overflow-x-hidden overflow-y-auto"
                    >

                        <SheetHeader>
                            <SheetTitle className="font-semibold text-left text-lg">Navigation</SheetTitle>
                        </SheetHeader>

                        <div className="flex flex-col items-start gap-4 my-4 text-sm">

                            <div className="flex items-center gap-2">
                                <span className="text-gray-600">Home</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600">About</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600">Contact</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600">Travels</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="text-gray-600">Bookings</span>
                            </div>

                        </div>

                        <SheetHeader>
                            <SheetTitle className="font-semibold text-left text-lg">More</SheetTitle>
                        </SheetHeader>

                        <div className="flex flex-col items-start gap-6 mt-4 text-sm">

                            <div className="flex items-center gap-2">
                                <span className="font-medium">LKR</span>
                                <span className="text-gray-600">Sri Lankan Rupee</span>
                            </div>


                            <div className="flex items-center gap-2">
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                                    alt="Flag"
                                    className="w-5 h-5"
                                    loading="lazy"
                                />
                                <span className="text-gray-600">English (US)</span>
                            </div>


                            <div className="space-y-4">
                                <Button variant="ghost" className="flex items-center gap-2">
                                    <Phone className="w-5 h-5" />
                                    Download Android App
                                </Button>
                                <Button variant="ghost" className="flex items-center gap-2">
                                    <Gift className="w-5 h-5" />
                                    Genius loyalty program
                                </Button>
                                <Button variant="ghost" className="flex items-center gap-2">
                                    <Home className="w-5 h-5" />
                                    List your property
                                </Button>
                            </div>


                            <div>
                                <h3 className="font-medium text-gray-800">Help and Support</h3>
                                <div className="space-y-4 mt-2">
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <HelpCircle className="w-5 h-5" />
                                        Contact Customer Service
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Flag className="w-5 h-5" />
                                        Partner dispute
                                    </Button>
                                </div>
                            </div>


                            <div>
                                <h3 className="font-medium text-gray-800">Inspiration</h3>
                                <div className="space-y-4 mt-2">
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Percent className="w-5 h-5" />
                                        Deals
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <BookOpen className="w-5 h-5" />
                                        Travel articles
                                    </Button>
                                </div>
                            </div>


                            <div>
                                <h3 className="font-medium text-gray-800">Settings and Legal</h3>
                                <div className="space-y-4 mt-2">
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Info className="w-5 h-5" />
                                        About Serviced Apartments
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Briefcase className="w-5 h-5" />
                                        Careers
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Star className="w-5 h-5" />
                                        Become an Host
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Newspaper className="w-5 h-5" />
                                        Press center
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Shield className="w-5 h-5" />
                                        Privacy & cookies
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <FileText className="w-5 h-5" />
                                        Terms & conditions
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Scale className="w-5 h-5" />
                                        Legal
                                    </Button>
                                    <Button variant="ghost" className="flex items-center gap-2">
                                        <Edit3 className="w-5 h-5" />
                                        Content guidelines and reporting
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </SheetContent>

                </Sheet>
            </div>

            <AuthModal isOpen={isModalOpen} onOpenChange={setModalOpen} activeTab={activeTab} />
        </nav>
    );
};

export default Navbar;

{/* <LoginButton>
                <Button variant='secondary' size='lg' className='auth-buttons'>
                    Sign in
                </Button>
            </LoginButton> */}