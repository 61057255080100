import React from 'react'
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarShortcut,
    MenubarTrigger,
} from "@/components/ui/menubar"
import { logout } from "@/actions/services/logout";
import { LogOut, Mail } from 'lucide-react';

const UserAvatar = () => {
    const handleLogout = async () => {
        const response = await logout();

        if (response.success) {
            console.log(response.success)
            window.location.reload();
        } else {
            console.error(response.error);
        }
    };

    return (
        <Menubar className="w-min bg-transparent border-none shadow-none focus:!bg-transparent">
            <MenubarMenu>
                <MenubarTrigger className="w-min bg-transparent">
                    <HoverCard>
                        <HoverCardTrigger asChild className="cursor-pointer bg-transparent">
                            <Avatar>
                                <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
                                <AvatarFallback>CN</AvatarFallback>
                            </Avatar>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-80 font-poppins absolute right-0">
                            <div className="flex justify-between space-x-4">
                                <Avatar>
                                    <AvatarImage src="https://github.com/shadcn.png" />
                                    <AvatarFallback>VC</AvatarFallback>
                                </Avatar>
                                <div className="space-y-1">
                                    <h4 className="text-sm font-semibold text-left">@example_name</h4>
                                    <p className="text-sm text-left">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    </p>
                                    <div className="flex items-center pt-2">
                                        <Mail className="mr-2 h-4 w-4 opacity-70" />{" "}
                                        <span className="text-xs text-muted-foreground">
                                            customer@example.com
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </HoverCardContent>
                    </HoverCard>
                </MenubarTrigger>
                <MenubarContent className="font-poppins absolute -right-10">
                    <MenubarItem className="px-5 cursor-pointer" onClick={handleLogout}>
                        <div className="flex items-center">
                            <LogOut className="mr-2 h-4 w-4 opacity-70" />{" "}
                            <span className="text-xs text-muted-foreground">
                                Logout
                            </span>
                        </div>
                    </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
        </Menubar>

    )
}

export default UserAvatar